<template>
    <section class="about">

        <div class="about_wrapper" :class="{reverse: this.reverse}">
            <div class="intro_img">
                <img :src="this.img" :alt="title">
            </div>

            <div class="intro_info">

                <div class="subtitle" v-if="subtitle">
                    <p>{{subtitle}}</p>
                </div>

                <div class="title" v-if="title">
                    <h3>{{title}}</h3>
                </div>

                <div class="description" v-if="description">
                    <p v-html="description"></p>
                </div>

                <div class="btnSafety" v-if="safety && specification">
                    <button class="safety" @click="save(safetyLink)">

                        <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M16.944 6.112C16.507 2.67 13.56 0 10 0C7.244 0 4.85 1.61 3.757 4.149C1.609 4.792 0 6.82 0 9C0 11.657 2.089 13.815 4.708 13.971V14H15.99V13.997L16 14C18.206 14 20 12.206 20 10C19.9988 9.10352 19.6971 8.23332 19.1431 7.5285C18.5891 6.82368 17.8148 6.32494 16.944 6.112ZM6 7H9V4H11V7H14L10 12L6 7Z"
                                fill="white" />
                        </svg>

                        <p>{{safetyTitle}}</p>
                    </button>
                    <br>
                    <button class="specification" @click="save(specificationLink)">

                        <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M16.944 6.112C16.507 2.67 13.56 0 10 0C7.244 0 4.85 1.61 3.757 4.149C1.609 4.792 0 6.82 0 9C0 11.657 2.089 13.815 4.708 13.971V14H15.99V13.997L16 14C18.206 14 20 12.206 20 10C19.9988 9.10352 19.6971 8.23332 19.1431 7.5285C18.5891 6.82368 17.8148 6.32494 16.944 6.112ZM6 7H9V4H11V7H14L10 12L6 7Z"
                                fill="#00C0C7" />
                        </svg>

                        <p>{{specificationTitle}}</p>
                    </button>
                    <br>
                    <button class="specification archive" @click="save(btnArchive)">

                        <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                    d="M16.944 6.112C16.507 2.67 13.56 0 10 0C7.244 0 4.85 1.61 3.757 4.149C1.609 4.792 0 6.82 0 9C0 11.657 2.089 13.815 4.708 13.971V14H15.99V13.997L16 14C18.206 14 20 12.206 20 10C19.9988 9.10352 19.6971 8.23332 19.1431 7.5285C18.5891 6.82368 17.8148 6.32494 16.944 6.112ZM6 7H9V4H11V7H14L10 12L6 7Z"
                                    fill="#00C0C7" />
                        </svg>

                        <p>{{archiveTitle}}</p>
                    </button>
                </div>

                <div class="btnSeeProduct" v-if="BtnSeeLink">

                    <a :href="BtnSeeLink" >
                        {{btnTitle}}
                    </a>

                </div>

                <a v-if="btnLink && btnTitle"  :href="btnLink">
                    {{btnTitle}}
                    <svg width="22" height="14" viewBox="0 0 22 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M14.2931 1.70711C13.9026 1.31658 13.9026 0.683417 14.2931 0.292893C14.6837 -0.0976311 15.3168 -0.0976311 15.7074 0.292893L21.7073 6.29289C22.0979 6.68342 22.0979 7.31658 21.7073 7.70711L15.7074 13.7071C15.3168 14.0976 14.6837 14.0976 14.2931 13.7071C13.9026 13.3166 13.9026 12.6834 14.2931 12.2929L18.586 7.99998H1.01103C0.452653 7.99998 0 7.55227 0 6.99998C0 6.4477 0.452653 5.99998 1.01103 5.99998H18.586L14.2931 1.70711Z"
                            fill="#20C8CE" />
                    </svg>
                </a>

                <a class="patent" v-if="btnPatent" target="_blank" :href="btnPatent">
                    <p>{{btnTitle}}</p>
                    <svg width="22" height="14" viewBox="0 0 22 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M14.2931 1.70711C13.9026 1.31658 13.9026 0.683417 14.2931 0.292893C14.6837 -0.0976311 15.3168 -0.0976311 15.7074 0.292893L21.7073 6.29289C22.0979 6.68342 22.0979 7.31658 21.7073 7.70711L15.7074 13.7071C15.3168 14.0976 14.6837 14.0976 14.2931 13.7071C13.9026 13.3166 13.9026 12.6834 14.2931 12.2929L18.586 7.99998H1.01103C0.452653 7.99998 0 7.55227 0 6.99998C0 6.4477 0.452653 5.99998 1.01103 5.99998H18.586L14.2931 1.70711Z"
                            fill="#20C8CE" />
                    </svg>
                </a>

            </div>
        </div>

    </section>
</template>

<script>
    export default {
        name: "sectionRigtLeft",
        locale: window.localStorage.getItem('locale'),
        props: ['subtitle', 'title', 'description', 'btnLink', 'btnTitle', 'reverse', 'img', 'safety', 'specification',
            'BtnSeeLink', 'safetyLink', 'specificationLink', 'btnPatent', 'specificationTitle', 'safetyTitle', 'archiveTitle', 'btnArchive'
        ],
        methods: {
            save(link) {
                let links = link;
                window.open(links, '_blank');
            }
        }
    }
</script>

<style scoped lang="scss">
    @import '../../assets/scss/index';

    .about {
        @include sectionPadding;

        & .about_wrapper {
            display: flex;
            justify-content: center;
            margin: 65px auto;

            @media (max-width: 1200px) {
                margin: 50px auto;
            }

            @media (max-width: 993px) {
                margin: 50px 20px;
                justify-content: center;
            }


            @media (max-width: 576px) {
                flex-direction: column;
                align-items: center;
            }

            & .intro_img {

                @media (max-width: 1200px) {
                    max-width: 412px;
                    max-height: 412px;
                }

                & img {
                    object-fit: contain;
                    max-width: 512px;
                    max-height: 512px;
                    width: 100%;
                    height: 100%;
                    margin-right: 20px;

                    @media (max-width: 1200px) {
                        max-width: 412px;
                        max-height: 412px;
                    }

                    @media (max-width: 769px) {
                        max-width: 312px;
                        max-height: 312px;
                    }

                    @media (max-width: 576px) {
                        max-width: 212px;
                        max-height: 212px;
                    }
                }
            }

            & .intro_info {
                width: 50%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                margin-right: 15px;

                @media (max-width: 576px) {
                    width: 100%;
                }

                & .subtitle {
                    & p {
                        @include subtitle ($colorLightTitle);
                        text-align: left !important;
                    }
                }

                & .title {
                    & h3 {
                        margin: 0;
                        @include title;
                        text-align: left !important;
                    }
                }

                & .description {
                    & p {

                        @include description;
                        @include SectionRestriction(50);
                        text-align: justify !important;
                        line-height: 28px !important;
                    }
                }

                & a {
                    display: flex;
                    align-items: center;
                    @include btnNoBcg;

                    & svg {
                        margin-left: 10px;
                    }
                }

                & .btnSafety {
                    margin-top: 10px;

                    @media (max-width: 768px) {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        flex-wrap: wrap;
                    }


                    @media (max-width: 575px) {
                        margin: 0 auto 50px;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;

                    }

                    & .safety {
                        @include btnSafety($colorLinkActive, white, $colorLinkHover);

                        & p {
                            @include Safety(white);
                        }

                        @media (max-width: 768px) {
                            margin-right: 15px;
                        }
                    }

                    & .specification {
                        @include btnSafety(white, $colorLinkActive, $colorLinkHover);

                        & p {
                            @include Safety($colorLinkActive);

                            &:hover {
                                color: white !important;
                            }
                        }


                        @media (max-width: 768px) {
                                &:last-child {
                                    margin-top: 15px;
                                }
                        }



                        @media (max-width: 630px) {
                            margin-top: 15px;
                            margin-right: 15px;
                        }
                        @media (max-width: 575px) {
                            margin-top: 0;
                            &:last-child {
                                margin-top: 0;
                            }
                        }


                    }

                    & .archive {
                        & svg {
                            margin: 0 0 0 13px !important;
                        }
                        & p {
                            margin: 0 auto 0 13px !important;
                        }
                    }
                }

                & .btnSeeProduct {

                    & a {
                        margin-top: 20px;
                        line-height: 58px !important;
                        text-align: center !important;
                        @include btn;

                        @media (max-width: 575px) {
                            line-height: 40px !important;
                            font-size: 14px;
                        }
                    }
                }

                & .patent {

                    margin-top: 20px;
                    @include btnSafety($colorLinkActive, white, $colorLinkHover);

                    & p {
                        @include Safety(white);
                    }

                    @media (max-width: 768px) {
                        margin-right: 10px;
                    }

                    @media (max-width: 575px) {
                        margin: 20px auto 0 !important;
                    }
                }
            }
        }

        & .reverse {
            flex-direction: row-reverse !important;

            @media (max-width: 576px) {
                flex-direction: column !important;
            }
        }

    }
</style>
